import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import { Container, Row, Col } from 'components/ui/wrapper';
import Pagination from 'components/blog/pagination';
import Filters from 'containers/stories/filters';
import AuthorProfilesSection from 'containers/stories/author-profiles';
import StoriesTextArea from 'containers/stories/text-area';
import Blog from 'components/blog';
import { FeaturedStoriesSectionWrapper } from 'containers/stories/featured-stories-section/featured-stories-section.style';

interface Props {
  pageContext: {
    currentPage: number;
    numberOfPages: number;
    hero?: {
      title: string;
      button: {
        title: string;
        link: string;
      };
    };
  };
  data: {
    content: {
      edges: any[];
    };
  };
  location: any;
  headingStyle?: object;
}

const BlogList: FC<Props> = ({ data, pageContext, location, ...restProps }) => {
  const blogs = data.content.edges.map((nodes) => nodes.node);

  const { currentPage, numberOfPages } = pageContext;

  return (
    <Layout location={location}>
      <SEO title="List Large Image" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={pageContext?.hero?.title}
          button={pageContext?.hero?.button}
          hero="stories"
        />
        <Filters />
        <FeaturedStoriesSectionWrapper className="left-red-slice sm-no-bg-image">
          <Container>
            <Row>
              {blogs.map((story) => (
                <Col lg={6} key={`stories-${story.id}`}>
                  <Blog content={story} />
                </Col>
              ))}
            </Row>
          </Container>
          <Pagination
            rootPage="/news"
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </FeaturedStoriesSectionWrapper>
        <AuthorProfilesSection />
        <StoriesTextArea />
      </main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    content: allStoriesJson(
      sort: { fields: postedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          postedDate(formatString: "LL")
          categories {
            title
          }
          author {
            name
          }
          photo {
            childImageSharp {
              fluid(maxWidth: 770, maxHeight: 420, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;

BlogList.propTypes = {
  headingStyle: PropTypes.object,
};

BlogList.defaultProps = {
  headingStyle: {
    as: 'h3',
    mb: '70px',
    textalign: 'center',
    child: {
      color: 'primary',
    },
    responsive: {
      medium: {
        mb: '50px',
      },
    },
  },
};

export default BlogList;
